import React from 'react';
import {navigate} from 'gatsby-link';
import styled from 'styled-components';
import {Form, Input, message} from 'antd';
import {BREAK_POINTS} from '../domain/Constants';
import Button from './Button';
import * as AppContext from '../AppContext';

const _initialValues = {
  name: '',
  phone: '',
  email: '',
  tel: '',
  telExt: '',
  title: '',
  content: '',
};
function ContactForm(props) {
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const app = React.useContext(AppContext.Context);

  const onFinish = React.useCallback(
    async (values) => {
      setLoading(true);
      try {
        await app.actions.sendContactForm({
          title: values.title,
          name: values.name,
          phone: values.phone,
          tel: values.tel,
          telExt: values.telExt,
          email: values.email,
          content: values.content,
        });
        form.setFieldsValue(_initialValues);
        message.success('表單已送出！');
      } catch (err) {
        message.error('無法送出表單！');
      }
      setLoading(false);
    },
    [form, app.actions],
  );

  const onFinishFailed = React.useCallback(async (error) => {}, []);

  return (
    <Wrapper>
      <h4>商業合作</h4>
      <p>{`若您對法朋的通路合作或商品聯名販售有興趣，歡迎填寫表單與我們聯絡！\n也可直接來信至： lerubanpatisserie@gmail.com`}</p>
      <Form
        className="form"
        form={form}
        initialValues={_initialValues}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}>
        <div className="form-content">
          <div className="left">
            <Form.Item
              className="form-item"
              name="name"
              rules={[
                {
                  required: true,
                  message: '必填',
                },
              ]}>
              <Input disabled={loading} placeholder="姓名/稱謂" />
            </Form.Item>
            <Form.Item
              className="form-item"
              name="phone"
              rules={[
                {
                  required: true,
                  message: '必填',
                },
                {
                  pattern: /((?=(09))[0-9]{10})$/g,
                  message: '請確認電話號碼格式是否正確',
                },
              ]}>
              <Input disabled={loading} placeholder="手機號碼" />
            </Form.Item>
            <Form.Item className="form-item" name="tel">
              <Input disabled={loading} placeholder="市內電話" />
            </Form.Item>
            <Form.Item className="form-item" name="telExt">
              <Input disabled={loading} placeholder="分機號碼" />
            </Form.Item>
            <Form.Item
              className="form-item"
              name="email"
              rules={[
                {
                  required: true,
                  message: '必填',
                },
                {
                  type: 'email',
                  message: '電子郵件不合法',
                },
              ]}>
              <Input disabled={loading} placeholder="請填寫您的電子郵件" />
            </Form.Item>
          </div>
          <div className="right">
            <Form.Item
              className="form-item"
              name="title"
              rules={[
                {
                  required: true,
                  message: '必填',
                },
              ]}>
              <Input disabled={loading} placeholder="信件主旨" />
            </Form.Item>
            <Form.Item
              className="form-item"
              name="content"
              rules={[
                {
                  required: true,
                  message: '必填',
                },
              ]}>
              <Input.TextArea
                disabled={loading}
                placeholder="信件內容"
                style={{height: 200}}
              />
            </Form.Item>
          </div>
        </div>

        <div className="form-footer">
          <Button
            disabled={loading}
            type="submit"
            size="small"
            loading={loading}>
            確認送出
          </Button>
        </div>
      </Form>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > h4 {
    font-size: 24px;
    color: #595757;
    letter-spacing: 1.41px;
    text-align: center;
    margin-bottom: 20px;
  }
  & > p {
    font-size: 14px;
    color: #595757;
    letter-spacing: 2px;
    text-align: center;
    line-height: 30px;
    margin-bottom: 50px;
  }
  & .form {
    & > .form-content {
      display: flex;
      & .left,
      & .right {
        flex: 1;
      }

      & .left {
        margin-right: 40px;
      }
    }

    & > .form-footer {
      display: flex;
      justify-content: center;
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.tablet}px) {
    & .form {
      & > .form-content {
        flex-direction: column;
        & .left {
          margin-right: 0px;
        }
      }
    }
  }
`;

export default ContactForm;
