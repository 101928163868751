import {navigate} from 'gatsby';
import React, {useState} from 'react';
import styled from 'styled-components';
import * as AppContext from '../../AppContext';
import * as AppActions from '../../AppActions';
import ImageCarousel from '../../components/ImageCarousel';
import URL_PATH from '../../UrlPath';
import {BREAK_POINTS} from '../../domain/Constants';
import imgHomeBreakBanner from '../../images/home-break-banner.png';
import Button from '../../components/Button';
import ProductItem from '../../components/ProductItem';
import imgLogo from '../../images/logo.png';
import ContactForm from '../../components/ContactForm';
import SiteAnnouncementModal from '../../components/SiteAnnouncementModal';
import {Input} from 'antd';

const {Search} = Input;
const slugify = require('uslug');

function LandingPage(props) {
  const {
    pageContext: {homeData},
  } = props;
  const app = React.useContext(AppContext.Context);
  const {products, categoryTree} = app;
  const isMenuOpen = React.useMemo(() => app.isMenuOpen, [app.isMenuOpen]);
  const [popularProducts, setPopularProducts] = useState([]);

  const _onProductItemClick = React.useCallback((item) => {
    navigate(`${URL_PATH.product}/?id=${item.id}`);
  }, []);

  React.useEffect(() => {
    if (!homeData || !Array.isArray(homeData.popular_products)) {
      return setPopularProducts([]);
    }

    setPopularProducts(
      homeData.popular_products
        .map((pid) => products.find((p) => p.id === pid))
        .filter((p) => !!p),
    );
  }, [homeData, products]);

  const _cateList = React.useMemo(() => {
    return categoryTree ? categoryTree.children.slice(0, 5) : [];
  }, [categoryTree]);

  React.useEffect(() => {
    AppActions.setModal(null);
    if (homeData?.siteAnnouncementImageUrl) {
      AppActions.setModal(
        <SiteAnnouncementModal
          imgUrl={homeData.siteAnnouncementImageUrl}
          onClose={() => AppActions.setModal(null)}
        />,
      );
    }
  }, [homeData?.siteAnnouncementImageUrl]);

  const onSearch = (searchContext) => {
    if (!searchContext.trim()) {
      setPopularProducts(
        homeData.popular_products
          .map((pid) => products.find((p) => p.id === pid))
          .filter((p) => !!p),
      );
      return;
    }
    const nextPopularProducts = [...popularProducts].filter((product) => {
      return product.name.indexOf(searchContext) > -1;
    });
    setPopularProducts(nextPopularProducts);
  };

  if (!homeData) {
    return null;
  }

  return (
    <Wrapper isMenuOpen={isMenuOpen}>
      <div className="banner">
        <ImageCarousel
          images={homeData.banners.filter((b) => !!b.url)}
          ratio={550 / 1440}
          showThumbs={false}
          showIndicators={true}
          autoPlay={true}
          interval={5000}
        />
      </div>
      <div className="container" style={{marginBottom: 60}}>
        <div className="header">
          <h2>熱門商品</h2>
          <Search
            className="product-search"
            placeholder="請輸入關鍵字"
            allowClear
            enterButton="搜尋"
            size="middle"
            onSearch={onSearch}
          />
        </div>

        <div className="popular-products-content">
          {popularProducts.map((p, idx) => (
            <ProductItem
              key={idx}
              item={p}
              style={{margin: '35px 0'}}
              onItemClick={_onProductItemClick}
            />
          ))}

          {[1, 2, 3].map((idx) => (
            <div key={idx} className="filler" />
          ))}
        </div>

        <div style={{textAlign: 'center'}}>
          <Button
            shadow
            onClick={() => navigate(URL_PATH.productList)}
            style={{marginTop: 50}}>
            看全部商品
          </Button>
        </div>
      </div>
      <div className="full-width-section">
        <div className="overlap">
          <h3>Le Ruban Pâtisserie</h3>
          <p>天然 / 有機 / 極製</p>
        </div>
      </div>
      <div className="container">
        <div className="custom-content">
          <div className="info">
            <h4 className="title">{homeData.custom_section.title}</h4>
            <p>{homeData.custom_section.content}</p>
            <Button
              type="secondary"
              onClick={() =>
                homeData.custom_section.button_link &&
                navigate(homeData.custom_section.button_link)
              }>
              {homeData.custom_section.button_text}
            </Button>
          </div>
          <div
            className="image"
            style={{
              backgroundImage: `url("${homeData.custom_section.image}")`,
            }}
          />
        </div>
      </div>
      <div className="bussiness-section">
        <div className="content">
          <img src={imgLogo} width={200} height={88} />
          <div className="info">
            <h4>Le Ruban Pâtisserie 法朋烘焙甜點坊</h4>
            <p>營業時間 週一至週日 11:30-19:30</p>
            <p>最後取貨時間 週一至週日 19:00</p>
            <p>客服服務時間 10:00-17:00</p>
            <p>聯絡電話 02 8978 8332</p>
            <p>門市地址 10693台北市大安區仁愛路四段300巷20弄11號</p>
          </div>
        </div>
      </div>
      <div className="map-section">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14459.729915922895!2d121.5537954!3d25.0363654!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfeba6a0310cbc375!2zTGUgUnViYW4gUMOidGlzc2VyaWUg5rOV5pyL54OY54SZ55Sc6bue5Z2K!5e0!3m2!1szh-TW!2stw!4v1626082284587!5m2!1szh-TW!2stw"
          width="100%"
          height="350"
          style={{border: 0}}
          loading="lazy"
        />
      </div>
      <div className="contact-section" id="contact">
        <div className="content">
          <ContactForm />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-bottom: 40px;

  & > .banner {
    width: 100%;
  }

  & > .full-width-section {
    height: 450px;
    background: url('${imgHomeBreakBanner}');
    background-size: cover;

    & > .overlap {
      background-color: rgba(0, 0, 0, 0.15);
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    & h3 {
      font-size: 40px;
      color: #ffffff;
      letter-spacing: 0;
      margin-bottom: 20px;
    }

    & p {
      font-size: 25px;
      color: #ffffff;
      letter-spacing: 1.47px;
    }
  }

  & > .container {
    padding: var(--content-padding);
    max-width: var(--content-max-width);
    margin: 0 auto;
  }

  & .header {
    display: flex;
    margin-top: 50px;
    justify-content: center;
    margin-bottom: 35px;
    position: relative;

    h2 {
      font-size: 30px;
      color: #595757;
      letter-spacing: 1.76px;
      text-align: center;
      padding: 0px 36px 15px;
      border-bottom: 1px solid #cccccc;
    }
    & > .product-search {
      position: absolute;
      top: 0;
      right: 0;
      max-width: 300px;
    }

    .view-all {
      font-size: 13px;
      color: #595757;
      letter-spacing: 1.86px;
      line-height: 22px;
      position: absolute;
      right: 0px;
      bottom: 0px;
      cursor: pointer;
      padding: 10px 0px 0px 10px;
    }
  }

  & .popular-products-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & > .filler {
      width: calc(100% / 3 - 35px);
      height: 1px;
      background-color: transparent;
      visibility: hidden;
      opacity: 0;
    }
  }

  & .store-info-content {
    border-radius: 10px;
    overflow: hidden;

    & > .image {
    }

    & > .title {
      font-size: 24px;
      color: #595757;
      letter-spacing: 1.41px;
      text-align: left;
      padding: 20px;
      background-color: #fff;
      text-align: center;
    }
  }

  & .custom-content {
    margin-top: 80px;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    background-color: #ffffff;

    & > .info {
      flex: 1;
      padding: 40px 50px 70px;

      & > .title {
        font-size: 30px;
        color: #595757;
        letter-spacing: 1.76px;
        text-align: left;
        line-height: 60px;
        white-space: pre-wrap;
      }

      & > p {
        margin: 40px 0px;
        font-size: 14px;
        color: #595757;
        letter-spacing: 0;
        text-align: justify;
        line-height: 30px;
        white-space: pre-wrap;
      }
    }

    & > .image {
      flex-basis: 420px;
      flex-shrink: 0;
      background-size: cover;
      background-position: center;
    }
  }

  & .news-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & .news {
      width: 300px;
    }
  }

  & .cat-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & .cat:first-child {
      width: 100%;
    }

    & .cat {
      width: calc((100% - 50px) / 2);
      cursor: pointer;
      border-radius: 10px;
      margin-bottom: 50px;
      overflow: hidden;
      & .overlay {
        position: relative;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
          transparent,
          rgba(255, 255, 255, 0.2)
        );
      }
      & .cat-name {
        position: absolute;
        bottom: 30px;
        text-align: center;
        font-size: 20px;
        color: #595757;
        letter-spacing: 2px;
        text-align: center;
        width: 100%;
      }
    }
  }

  & .bussiness-section {
    background-color: #fff;
    & .content {
      padding: 80px var(--content-padding);
      max-width: var(--content-max-width);
      margin: 0 auto;
      display: flex;
      align-items: center;

      & > .info {
        margin-left: 150px;
        & > h4 {
          font-size: 24px;
          color: #595757;
          letter-spacing: 0;
          margin-bottom: 20px;
        }

        & > p {
          font-size: 14px;
          color: #595757;
          letter-spacing: 2px;
          line-height: 30px;
        }
      }
    }
  }

  & .contact-section {
    padding-top: 45px;
    margin-top: -45px;
    & .content {
      padding: 50px var(--content-padding) 70px;
      max-width: var(--content-max-width);
      margin: 0 auto;
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.tablet}px) {
    & .custom-content {
      flex-direction: column-reverse;
    }

    & .news-content {
      & > .news {
        width: calc((100% - 10px) / 2);
      }
    }

    & .bussiness-section {
      & .content {
        flex-direction: column;
        & > .info {
          margin-left: 0px;
          margin-top: 30px;
        }
      }
    }

    & .header {
      flex-direction: column;
      align-items: center;

      h2 {
      }
      & > .product-search {
        position: relative;
        max-width: 300px;
        margin: 48px 0 0 0;
      }

      .view-all {
      }
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.mobile}px) {
    & .news-content {
      & > .news {
        width: 100%;
      }
    }
  }
`;

export default LandingPage;
