import React from 'react';
import styled from 'styled-components';
import {Close} from '@styled-icons/material/Close';

function SiteAnnouncementModal(props) {
  const {onClose = () => 0, imgUrl} = props;
  return (
    <ContentWrapper>
      <Close
        size={30}
        onClick={onClose}
        color="#b79d7a"
        className="close-btn"
      />
      <img src={imgUrl} alt="announcement" />
    </ContentWrapper>
  );
}

const ContentWrapper = styled.div`
  position: relative;
  & > .close-btn {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.3));
    transition: all 300ms ease-in-out;
    :hover {
      transform: scale(1.1);
    }
  }
  & > img {
    max-height: calc(100vh - 200px);
    max-width: 100%;
    margin: 0 auto;
    display: block;
  }
`;

export default SiteAnnouncementModal;
